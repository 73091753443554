import { Flex, Image } from "@chakra-ui/react";
import { getImageWithSubRoute } from "utils";

import LandingContainer from "../LandingContainer";

import SectionTitle from "../SectionTitle";

const PARTNERS = [
  {
    key: "scb",
    imgLink: "/assets/landingPage/partners/leads/scb.svg",
    name: "SCB",
  },
  {
    key: "laserdigital",
    imgLink: "/assets/landingPage/partners/leads/ld.svg",
    name: "Laser Digital",
  },
  {
    key: "bankless",
    imgLink: "/assets/landingPage/partners/funds/bankless.svg",
    name: "Bankless Ventures",
  },
  {
    key: "hypersphere",
    imgLink: "/assets/landingPage/partners/funds/hypersphere.svg",
    name: "Hypersphere",
  },
  {
    key: "draperdragon",
    imgLink: "/assets/landingPage/partners/funds/draper.svg",
    name: "Draper Dragon",
  },
  {
    key: "dacm",
    imgLink: "/assets/landingPage/partners/funds/dacm.svg",
    name: "DACM",
  },
  {
    key: "cypher",
    imgLink: "/assets/landingPage/partners/funds/cypher.svg",
    name: "Cypher Captial",
  },
  {
    key: "arkstream",
    imgLink: "/assets/landingPage/partners/funds/arkstream.svg",
    name: "ArkStream Capital",
  },
  {
    key: "clustercapital",
    imgLink: "/assets/landingPage/partners/funds/cluster.svg",
    name: "Cluster Capital",
  },
  {
    key: "gsr",
    imgLink: "/assets/landingPage/partners/funds/gsr.svg",
    name: "GSR",
  },
  {
    key: "longhash",
    imgLink: "/assets/landingPage/partners/funds/longhash.svg",
    name: "LongHash Ventures",
  },
  {
    key: "sidedoor",
    imgLink: "/assets/landingPage/partners/funds/sidedoor.svg",
    name: "Side Door Ventures",
  },
  {
    key: "noia",
    imgLink: "/assets/landingPage/partners/funds/noia.svg",
    name: "NOIA",
  },
  {
    key: "htx",
    imgLink: "/assets/landingPage/partners/funds/htx.svg",
    name: "HTX Ventures",
  },
  {
    key: "viabtc",
    imgLink: "/assets/landingPage/partners/funds/viabtc.svg",
    name: "ViaBTC",
  },
  {
    key: "dwflabs",
    imgLink: "/assets/landingPage/partners/funds/dwf.svg",
    name: "DWF Labs",
  },
  {
    key: "coinseeker",
    imgLink: "/assets/landingPage/partners/funds/coinseeker.svg",
    name: "Coinseeker",
  },
  {
    key: "tulipa",
    imgLink: "/assets/landingPage/partners/funds/tulipa.svg",
    name: "tulipa",
  },
  {
    key: "web3port",
    imgLink: "/assets/landingPage/partners/funds/web3port.svg",
    name: "Web3port",
  },
];

const Partners = () => {
  return (
    <LandingContainer>
      <Flex
        alignItems={{ base: "space-between", md: "center" }}
        mb={{ base: 6, md: 10 }}
        flexDir={{ base: "column", md: "row" }}
      >
        <SectionTitle
          w="412px"
          maxW="100%"
          title="Meet our partners"
          mb={{ base: 5, md: 0 }}
        />

        <Flex
          justifyContent="space-between"
          alignItems="space-between"
          flexDir={{ base: "column", md: "column" }}
          gap="40px"
          width={{ base: "100%", sm: "100%", md: "100%", lg: "90%" }}
        >
          <Flex
            gap="8px"
            flexDir={"row"}
            flexWrap={"wrap"}
            alignItems={{ base: "space-between", md: "center" }}
            justifyContent={{ base: "space-between", md: "center" }}
          >
            {PARTNERS.map((data) => (
              <Flex
                key={data.name}
                bg={"#CAE8E8"}
                p={"15px"}
                borderRadius={"20px"}
                w={{
                  base: "45%",
                  lg: "30%",
                  md: "30%",
                  sm: "45%",
                }}
                minWidth={"145px"}
                h={{
                  base: "60px",
                  md: "120px",
                }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image
                  src={getImageWithSubRoute(data.imgLink)}
                  alt={data.name}
                  boxSize="120px"
                  objectFit="scale-down"
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </LandingContainer>
  );
};

export default Partners;
