import {
  Box,
  Text,
  Button,
  InputGroup,
  InputRightElement,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { SyntheticEvent, useEffect, useState } from "react";
import { subscribeEmail } from "services";
import { validateEmail } from "utils";

const EmailModal = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (email) {
      const isValidEmail = validateEmail(email);

      if (isValidEmail) {
        setError("");
      } else {
        setError("Please enter correct email ID");
      }
    }
  }, [email]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter correct email ID");
      return;
    }

    setIsSubscribing(true);

    const formData: any = new FormData();
    formData.append("Email", email);

    const response = await subscribeEmail(formData);

    if ((response as any).error) {
      toast({
        description: "Failed to subscribe",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setIsSubscribing(false);
      setEmail("");
      return;
    }

    toast({
      description: "Email subscribed successfully",
      status: "success",
      duration: 2000,
      position: "top",
      isClosable: true,
    });
    setIsSubscribing(false);
    setEmail("");
  };

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit}>
        <InputGroup
          size="lg"
          mt="0"
          mb="0"
          w={{ base: "320px", md: "572px" }}
          _hover={{
            borderColor: "backgroundDivider",
          }}
          _focus={{
            borderWidth: "1px",
            borderColor: "backgroundDivider",
          }}
          borderRadius="16px"
          borderColor="backgroundDivider"
          bg="white"
        >
          <Input
            bg="white"
            type="email"
            required
            placeholder="Email address"
            value={email}
            fontSize="1rem"
            fontWeight="500"
            h="58px"
            pl="1rem"
            pr="0"
            onChange={(e) => setEmail(e.target.value)}
            _placeholder={{ color: "textSecondary" }}
            color="textSecondary"
            width="calc(100% - 92px)"
            border="0px"
            borderColor="white"
            borderRadius="10px"
            _focus={{
              outline: 0,
              boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
              border: "0px",
            }}
            _hover={{
              outline: 0,
              boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
              border: "0px",
            }}
          />

          <InputRightElement
            h="58px"
            w={{ base: "76px", md: "130px" }}
            mr="6px"
            zIndex="1"
          >
            <Button
              type="submit"
              p={{ base: "10px 8px", md: "10px 28px" }}
              h="46px"
              bg="#075A5A"
              border="1px"
              borderColor="#075A5A"
              borderRadius="12px"
              w={{ base: "80px", md: "128px" }}
              _hover={{
                bg: "#075A5A",
              }}
              _focus={{
                bg: "#075A5A",
              }}
              isDisabled={error as unknown as boolean}
            >
              {/* Subscribe */}
              {isSubscribing ? (
                <Spinner size="md" color="primary" />
              ) : (
                <Text color="white" fontSize="14px" fontWeight="500">
                  Sign Up
                </Text>
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
        {error && (
          <Text textStyle="p14" color="#e53e3e" mt="0.5rem" textAlign="left">
            {error}
          </Text>
        )}
      </form>
    </Box>
  );
};

export default EmailModal;
