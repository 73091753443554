import { Box, Button, Flex, Text } from "@chakra-ui/react";
import UTMLink from "@components/UTMLink";
import { LP_RESTAKE, LP_SUBSCRIBE } from "@constants/analytics";
// TODO: remove web sdk
import { useTVL } from "@stader-labs/web-sdk";

import { numberToCurrency, trackEvent } from "utils";

const Hero = () => {
  const tvlData = useTVL({});

  const handleSubcribeClick = () => {
    if (typeof window !== "undefined") {
      //go to email section of the page
      const emailSection = document.getElementById("subscribe");
      if (emailSection) {
        emailSection.scrollIntoView({ behavior: "smooth" });
      }
    }

    trackEvent(LP_SUBSCRIBE, {
      triggerer: "hero_section",
    });
  };

  return (
    <Box
      h={{ base: "724px", md: "900px" }}
      mb={{ base: "-1px", md: 0 }}
      className="heroSection"
    >
      <Flex
        flexDir="column"
        gap="32px"
        alignItems="center"
        textAlign="center"
        p={{ base: "80px 16px 0", md: "108px 0 0" }}
      >
        <Box>
          <Text
            fontSize={{ base: "40px", md: "80px" }}
            fontWeight="600"
            letterSpacing={{ base: "-2px", md: "-4px" }}
            lineHeight="120%"
            color="textPrimary"
          >
            Liquid restaking with rsETH
          </Text>

          <Flex flexDir="column" gap="4px" mt="24px">
            <Text fontSize="24px" lineHeight="28px" fontWeight="700">
              {tvlData.tvl?.usdTvl
                ? `$${numberToCurrency(tvlData.tvl?.usdTvl.toString())}`
                : "-"}
            </Text>
            <Text fontSize="12px" lineHeight="18px" fontWeight="400">
              ASSETS STAKED
            </Text>
          </Flex>
        </Box>

        <Flex
          gap={{ base: "6px", md: "16px" }}
          fontSize="14px"
          fontWeight="600"
          lineHeight="24px"
        >
          <UTMLink
            href="/restake"
            onClick={() => {
              trackEvent(LP_RESTAKE, {
                triggerer: "hero_section",
              });
            }}
          >
            <Button
              variant="unstyled"
              h={{ base: "40px", md: "56px" }}
              background="textPrimary"
              border="1px solid textPrimary"
              borderColor="textPrimary"
              borderRadius="16px"
              color="white"
              w={{ base: "162px", md: "220px" }}
            >
              Restake now
            </Button>
          </UTMLink>

          <Button
            variant="unstyled"
            h={{ base: "40px", md: "56px" }}
            background="transparent"
            border="1px solid"
            borderColor="textPrimary"
            borderRadius="16px"
            color="textPrimary"
            onClick={handleSubcribeClick}
            w={{ base: "162px", md: "220px" }}
          >
            Sign up now
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Hero;
