import { Flex, Text, Box } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";

import LandingContainer from "../LandingContainer";
import SectionTitle from "../SectionTitle";

const INTRODUCTION_DATA = [
  {
    imgKey: "/assets/landingPage/introduction/c-token1.svg",
    title: "One token for all",
    subTitle:
      "rsETH is a single liquid restaked token for all accepted ETH LSTs",
  },
  {
    imgKey: "/assets/landingPage/introduction/c-token2.svg",
    title: "Designed for liquidity",
    subTitle:
      "Access restaking rewards along with instant liquidity and DeFi composability",
  },
];

const Introduction = () => {
  return (
    <LandingContainer>
      <Flex
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        p="40px 0 0px"
        flexDir={{ base: "column", md: "row" }}
        gap="48px"
      >
        <SectionTitle
          title="Introducing rsETH"
          maxW={{ base: "100%", md: "412px" }}
        />
        <Flex gap="40px" flexDir={{ base: "column", md: "row" }}>
          {INTRODUCTION_DATA.map((data) => (
            <Flex
              key={data.title}
              flexDir={{ base: "row", md: "column" }}
              gap={{ base: "16px", md: "40px" }}
              maxW={{ base: "100%", md: "330px" }}
              alignItems={{ base: "center", md: "flex-start" }}
            >
              <Box
                height={{ base: "80px", md: "160px" }}
                width={{ base: "80px", md: "152px" }}
                flexShrink={0}
              >
                <BaseImage
                  height={160}
                  width={152}
                  src={data.imgKey}
                  alt={data.title}
                />
              </Box>
              <Box>
                <Text
                  color="textPrimary"
                  fontSize={{ base: "18px", md: "24px" }}
                  lineHeight="130%"
                  fontWeight="600"
                >
                  {data.title}
                </Text>
                <Text
                  color="textSecondary"
                  mt="16px"
                  fontSize={{ base: "12px", md: "20px" }}
                  lineHeight="130%"
                  fontWeight="400"
                >
                  {data.subTitle}
                </Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </LandingContainer>
  );
};

export default Introduction;
