import { Flex, Text, Box } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import { IconMapping } from "utils";

import LandingContainer from "../LandingContainer";

const FOR_RESTAKERS_DATA = [
  {
    imgKey: "coins",
    text: "No lock-up for restaked tokens",
  },
  {
    imgKey: "user_search",
    text: "Easily manage multiple restaking rewards",
  },
  {
    imgKey: "arm_coins",
    text: "Whitelisted services and validators",
  },
];

const WhyLiquidRestaking = () => {
  return (
    <LandingContainer pb="0" bg="#075A5A">
      <Flex flexDir="column" gap={{ base: "40px", md: "80px" }}>
        <Text
          fontSize={{ base: "24px", md: "48px" }}
          fontWeight="600"
          letterSpacing={{ base: "-1.2px", md: "-2px" }}
          color="white"
        >
          Why liquid restaking?
        </Text>

        <Flex
          gap={{ base: "40px", md: "80px" }}
          alignItems={{ base: "flex-start", md: "center" }}
          justify="space-between"
          flexDir={{ base: "column-reverse", md: "row" }}
        >
          <Flex flexDir="column" gap="40px" color="white">
            {FOR_RESTAKERS_DATA.map((data) => (
              <Flex key={data.imgKey} gap="24px" alignItems="center">
                <Box
                  height={{ base: "24px", md: "48px" }}
                  width={{ base: "24px", md: "48px" }}
                  pos="relative"
                >
                  <BaseImage
                    fill
                    alt={data.imgKey}
                    src={IconMapping[data.imgKey]}
                  />
                </Box>
                <Text
                  fontSize={{ base: "14px", md: "24px" }}
                  fontWeight="400"
                  lineHeight={{ base: "160%", md: "28px" }}
                  letterSpacing="-0.96px"
                >
                  {data.text}
                </Text>
              </Flex>
            ))}
          </Flex>
          <BaseImage
            src="/assets/landingPage/restakers.svg"
            height={362}
            width={520}
            alt="icon"
          />
        </Flex>
      </Flex>
    </LandingContainer>
  );
};

export default WhyLiquidRestaking;
