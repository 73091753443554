import LandingContainer from "../LandingContainer";

import SubscribeCard from "./SubscribeCard";

const Subscribe = () => {
  return (
    <LandingContainer
      pb="0"
      pt={{ base: "16px", md: "80px" }}
      bg="backgroundDivider"
      id="subscribe"
    >
      <SubscribeCard
        title="Stay updated with Kelp"
        message="Join the community and get early alpha and be the first to know about exclusive rewards"
      />
    </LandingContainer>
  );
};

export default Subscribe;
