import { Text } from "@chakra-ui/react";

const SectionTitle = ({ title, ...rest }: any) => {
  return (
    <Text
      fontSize={{ base: "24px", md: "40px" }}
      fontWeight="600"
      letterSpacing={{ base: 0, md: "-2px" }}
      color="textPrimary"
      {...rest}
    >
      {title}
    </Text>
  );
};

export default SectionTitle;
