import { Flex, Link, Text, Box } from "@chakra-ui/react";

import { AUDITS_DATA } from "@constants/common";

import LandingContainer from "../LandingContainer";
import SectionTitle from "../SectionTitle";
import BaseImage from "@components/BaseImage";

const Audits = () => {
  return (
    <LandingContainer>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDir={{ base: "column", md: "row" }}
        gap="40px"
      >
        <SectionTitle w="412px" maxW="100%" title="Secured by top auditors" />

        <Flex
          width={{ base: "100%", md: "auto" }}
          justifyContent="space-between"
          maxW={{ base: "460px", md: "100%" }}
        >
          {AUDITS_DATA.map((data) => (
            <Flex
              key={data.key}
              w={{ base: "152px", md: "280px", lg: "350px" }}
              h={{ base: "88px", md: "117px" }}
              alignItems="center"
              flexDir="column"
              justifyContent="space-between"
            >
              <Box
                width={{ base: "144px", md: "220px" }}
                height={{ base: "40px", md: "64px" }}
                pos="relative"
              >
                <BaseImage src={data.imgLink} alt={data.key} fill />
              </Box>

              <Link href={`${data.link}`} target="_blank" rel="noreferrer">
                <Text
                  fontSize={{ base: "12px", md: "16px" }}
                  lineHeight="130%"
                  fontWeight={{ base: "500", md: "600" }}
                >
                  Get full report
                </Text>
              </Link>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </LandingContainer>
  );
};

export default Audits;
