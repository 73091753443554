import { Center, Text, Box, ChakraProps } from "@chakra-ui/react";
import SectionTitle from "../SectionTitle";
import EmailModal from "./Email";

export type TSubscribeProps = {
  title?: string;
  message?: string;
  titleStyle?: ChakraProps;
  messageSyle?: ChakraProps;
  emailBoxSyle?: ChakraProps;
};
const SubscribeCard = (props: TSubscribeProps) => {
  const {
    title,
    message,
    titleStyle = {},
    messageSyle = {},
    emailBoxSyle = {},
  } = props;
  return (
    <Center flexDir="column" alignItems={{ base: "flex-start", md: "center" }}>
      {title && <SectionTitle title={title} {...titleStyle} />}
      <Text
        color="textSecondary"
        fontWeight="500"
        fontSize={{ base: "10px", md: "16px" }}
        lineHeight={{ base: "100%", md: "22px" }}
        mt="16px"
        {...messageSyle}
      >
        {message}
      </Text>
      <Box mt={{ base: "2rem", md: "4rem" }} {...emailBoxSyle}>
        <EmailModal />
      </Box>
    </Center>
  );
};

export default SubscribeCard;
