import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface LandingContainerProps extends BoxProps {
  children: ReactNode;
}

const LandingContainer = ({ children, ...rest }: LandingContainerProps) => {
  return (
    <Flex bg="backgroundCanvas" w="full" justifyContent="center" {...rest}>
      <Box
        bg="backgroundCanvas"
        w="full"
        p={{ base: "64px 16px", md: "80px 24px", lg: "80px 160px" }}
        {...rest}
        maxW="1512px"
      >
        {children}
      </Box>
    </Flex>
  );
};

export default LandingContainer;
