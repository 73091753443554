import { Center } from "@chakra-ui/react";

import type { NextPage } from "next";
import Head from "next/head";

import Audits from "@components/LandingPage/Audits";
import Footer from "@components/LandingPage/Footer";
import Partners from "@components/LandingPage/Partners";
import Subscribe from "@components/LandingPage/Subscribe";
import SupportedToken from "@components/LandingPage/SupportedTokens";
import Introduction from "@components/LandingPage/Introduction";
import Hero from "@components/LandingPage/Hero";
import WhyLiquidRestaking from "@components/LandingPage/WhyLiquidRestaking";
import HeaderNav from "@components/HeaderNav/HeaderNav";

const Header = () => (
  <Head>
    <title>
      Liquid restaking | Restake ETH or LSTs, mint rsETH and earn Kelp Miles!
    </title>
    <meta
      name="description"
      content="Unlock higher rewards and DeFi utility for restaked assets through liquid restaking with rsETH. Get dual rewards with EigenLayer Restaked Points and Kelp Miles."
    />
    <link rel="icon" href="/favicon.ico" />
    <meta name="robots" content="index, follow" />
  </Head>
);

const Home: NextPage = () => {
  return (
    <>
      <Header />
      <Center flexDir="column">
        <HeaderNav />
        <Hero />
        <WhyLiquidRestaking />
        <div className="seperatorDark" />
        <Introduction />
        <SupportedToken />
        <Audits />
        <Partners />
        <div className="seperator" />
        <Subscribe />
        <div className="seperatorWithStar" />
        <Footer />
      </Center>
    </>
  );
};

export default Home;
