import { Flex, Text, Box } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";

import LandingContainer from "../LandingContainer";
import SectionTitle from "../SectionTitle";

const SupportedToken = () => {
  return (
    <LandingContainer>
      <Flex
        pb={{ base: "0", md: "20px" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <Flex flexDir="column" gap={{ base: "40px", md: "48px" }}>
          <SectionTitle title="Supported tokens" />

          <Box display={{ base: "block", md: "none" }}>
            <BaseImage
              src="/assets/landingPage/supported_tokens.svg"
              height={362}
              width={520}
              alt="icon"
            />
          </Box>

          <Flex
            flexDir="column"
            gap="24px"
            fontSize="16px"
            fontWeight="400"
            lineHeight="130%"
            opacity="0.6"
            width="100%"
            maxWidth={{ base: "100%", md: "360px" }}
            color="#070707"
          >
            <Text>
              Kelp supports ETH, stETH and ETHx. Kelp DAO may choose to
              introduce other collateral in the future.
            </Text>
          </Flex>
        </Flex>

        <Box
          h="488px"
          w="700px"
          pos="relative"
          display={{ base: "none", md: "block" }}
        >
          <BaseImage
            src="/assets/landingPage/supported_tokens.svg"
            alt="supported"
            fill
            objectFit="contain"
          />
        </Box>
      </Flex>
    </LandingContainer>
  );
};

export default SupportedToken;
